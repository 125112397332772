@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap");
/* Font definitions */
@font-face {
  font-family: "SpoqaHanSansNeo-Regular";
  src: url("./fonts/SpoqaHanSansNeo-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NanumBarunGothic";
  src: url("./fonts/NanumBarunGothicWeb.eot");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "NanumBarunGothic";
  src: url("./fonts/NanumBarunGothicWebBold.eot");
  font-weight: 700;
  font-style: normal;
}

/* Additional fonts */
@font-face {
  font-family: "NanumBarunpen";
  src: url("./fonts/NanumBarunpen.woff") format("woff");
}

@font-face {
  font-family: "Cafe24SsurroundAir";
  src: url("./fonts/Cafe24SsurroundAir.woff") format("woff");
}

@font-face {
  font-family: "GyeonggiBatang";
  src: url("./fonts/GyeonggiBatang.woff") format("woff");
}

@font-face {
  font-family: "UhBeeMiMi";
  src: url("./fonts/UhBeeMiMi.woff") format("woff");
}

img,
svg {
  -webkit-tap-highlight-color: transparent; /* Safari, Chrome, Android */
  -moz-tap-highlight-color: transparent; /* Firefox */
  -ms-tap-highlight-color: transparent; /* Internet Explorer/Edge */
  tap-highlight-color: transparent; /* 표준 (기본값) */
  user-select: none; /* 텍스트 선택 방지 */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  outline: none; /* 포커스 outline 제거 */
}

* {
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text-ellipsis-2-line {
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.hide-scroll {
  scrollbar-width: none;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
