.img-wrapper img {
  max-height: 600px;
}

.img-wrapper-sub {
  width: 92% !important;
  display: flex !important;
  justify-content: center;
}

.img-wrapper-sub img {
  max-height: 320px;
}

.swiper {
  margin-left: 1rem !important;
}

.swiper-wrapper {
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1.0) !important;
}

.indicator {
  padding-right: 2.5rem;
  padding-bottom: 1rem;
}
@media (min-width: 425px) {
  .navigator {
    display: none;
  }
}

@media (min-width: 768px) {
  .swiper {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .indicator {
    padding-right: 4rem;
    padding-bottom: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .navigator {
    display: flex;
  }
}
@media (min-width: 1440px) {
  .img-wrapper-sub {
    width: 70% !important;
  }
}

@media (min-width: 1680px) {
  .img-wrapper-sub {
    width: 60% !important;
  }
}
