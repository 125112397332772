body {
    height: 100%;
    background-color: #ffffff; 
}

.button-container {
    display: flex; /* Flexbox로 설정 */
    justify-content: center; /* 버튼을 수평 중앙에 배치 */
    align-items: center; /* 수직 중앙 정렬 */
    margin-top: 10px; /* 상단 여백 */
    width: 100%; /* 버튼 컨테이너 너비를 전체로 설정 */
    padding: 10px; /* 패딩 설정 */
}

.styled-button {
    width: 300px; /* 버튼 너비 */
    padding: 15px; /* 버튼 패딩 */
    font-size: 18px; /* 글자 크기 */
    background-color: #ffcc00; /* 노란색 배경 */
    color: #0033a0; /* 파란색 텍스트 */
    border: none;
    cursor: pointer;
    margin: 0 20px; /* 각 버튼 사이에 간격 추가 */
    border-radius: 10px; /* 둥근 모서리 */
    text-transform: uppercase; /* 텍스트 대문자 */
    font-weight: bold; /* 글자 굵기 */
}

.styled-button:hover {
    background-color: #ffd700; /* Hover 시 색상 변경 */
}


.ui {
    border: 0;
    border-radius: 10px;
    width: 70%;
    margin: 0 auto;
    padding: 10%;
    display: flex;
    gap: 30px;
    background-color: rgb(255, 255, 255); /* 검정색 배경 */
}

.container-left {
    width: 600px;
}

.container-right {
    width: 200px;
    display: flex;
    align-items: center;
}

.card {
    width: 700px;
    height: 200px;
    margin: 0 auto;
    background: #009cfc; /* L.POINT 카드와 비슷한 파란색 */
    border: 1px solid rgba(0,0,0,.3);
    border-radius: 15px;
    position: relative; /* 텍스트 위치 설정을 위해 relative */
    color: white; /* 텍스트는 흰색 */
}

.card .loving-life {
    position: absolute;
    top: 10px;
    left: 20px;
    font-size: 0.5.rem;
    font-weight: 400;
}

/* L.POINT 텍스트 크기 및 스타일 변경 */
.card .lpoint {
    position: absolute;
    top: 30px;
    left: 20px;
    font-size: 10rem; /* 글자 크기 조절 */
    font-weight: 1000; /* 굵게 설정 */
    color: white; /* 텍스트 색상 (흰색) */
}


.intern {
    padding: 20px;
}
.chip {
    height: 40px;
    position: relative;
    left: 87%;
    bottom: 20px;
}

.card-number {
    margin-top: 40px;
    font-size: 1.2rem;
    font-weight: 700;
    color: white; /* 카드 번호 흰색 */
    display: flex;
}


.card label {
    font-size: .7rem;
}

.card-infos {
    display: flex;
    width: 40%;
    justify-content: space-between;
    color: white; /* 카드 정보 흰색 */
}

#credit-card {
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-size: 1.1rem;
    color: white;
    text-transform: uppercase;
}

.number-container, .infos-container {
    width: 100%;
    text-align: center; /* 입력 필드와 라벨 중앙 정렬 */
}


input {
    border: 0;
    border-radius: 5px;
    padding: 10px;
    background-color: #333333; /* 입력 필드 어두운 색 */
    color: white;
    font-weight: 600;
    font-size: 1.1rem;
    outline: 0;
    width: 90%;
}

/* input[type="text"]:focus {
    border: 1px solid #95B8D1;
} */

input#valid-thru-text, input#cvv-text {
    width: 80%;
}

input[type="submit"] {
    width: 95%;
    background-color: #f7e8ad; /* L포인트 카드와 비슷한 노란색 */
    color: #0033a0; /* 파란색 텍스트 */
    cursor: pointer;
}

/* 신용카드와 동일한 가로길이 설정 */
input#card-number, input#valid-thru-text, input[type="submit"] {
    width: 100%; /* 신용카드와 같은 가로길이 */
    margin: 0 auto; /* 좌우 여백을 자동으로 */
    display: block; /* 중앙 정렬을 위해 block으로 변경 */
    text-align: center; /* 텍스트 중앙 정렬 */
}

.infos-container {
    display: flex;
}

@media (max-width: 1024px) {
    .ui {
        flex-direction: column-reverse;
    }

    .container-left, .container-right {
        width: 100%;
    }
}

#add {
  width: 100%; /* 버튼의 너비를 컨테이너에 맞춤 */
  padding: 10px;
  font-size: 16px;
  margin-top: 10px; /* 입력 필드와 버튼 사이의 여백 */
  background-color: #c7c410; /* 버튼 색상 */
  color: white; /* 버튼 글자색 */
  border: none;
  cursor: pointer;
}

#add:hover {
  background-color: #45a049; /* 버튼 호버시 색상 변화 */
}